import React from "react";
import { Route } from "react-router-dom";
import Authorized from "./Authorized";
import {Redirect} from "react-router";

function AuthorizedRoute({
                           component: Component,
                           render,
                           authority,
                           redirectPath,
                           ...rest
                         }) {
  return (
    <Authorized
      authority={authority}
      noMatch={
        <Route
          render={() => <Redirect to={{ pathname: redirectPath }} />}
        />
      }
    >
      <Route
        {...rest}
        render={props => (Component ? <Component {...props} /> : render(props))}
      />
    </Authorized>
  );
}

export default AuthorizedRoute;
