
export default function Authorized(props) {
  let t = JSON.parse(localStorage.getItem('token'));
  const { children, authority, noMatch } = props;

  let time = new Date().getTime()
  let token = t?.token
  if(t?.expire <= time){
    token = ''
  }

  if(token){
    return children;
  }
  if(!authority){
    return children;
  }
  return noMatch;
}
