import React,{Component} from 'react';
import PageLoading from "../../layout/PageLoading";
import Home from '../../pages/home'
import {Switch} from "react-router";
import AuthorizedRoute from "@/utils/AuthorizedRoute";

const r = [
  {
    exact: true,
    path: "/login",
    component: React.lazy(() => import('../../pages/user/login'))
  },{
    exact: true,
    path: "/",
    component: Home,
    authority: ["admin"],
    redirectPath: "/login"
  },{
    path: "/calendar",
    component: React.lazy(() => import('../../pages/calendar')),
    authority: ["admin"],
    redirectPath: "/login"
  },{
    path: "/project/:id",
    component: React.lazy(() => import('../../pages/project/detail')),
    authority: ["admin"],
    redirectPath: "/login"
  },{
    path: "/project",
    component: React.lazy(() => import('../../pages/project/list')),
    authority: ["admin"],
    redirectPath: "/login"
  },{
    path: "/dashbord",
    component: React.lazy(() => import('../../pages/dashbord')),
    authority: ["admin"],
    redirectPath: "/login"
  },{
    path: "/search",
    component: React.lazy(() => import('../../pages/search/index')),
    authority: ["admin"],
    redirectPath: "/login"
  },{
    path: "/clue",
    component: React.lazy(() => import('../../pages/clue')),
    authority: ["admin"],
    redirectPath: "/login"
  },{
    path: "/settings",
    component: React.lazy(() => import('../../pages/settings')),
    authority: ["admin"],
    redirectPath: "/settings"
  }
]

class Router extends Component{

  render() {
    return(
      <React.Fragment>
        <React.Suspense fallback={<PageLoading />}>
          <Switch>
            {r.map(rc => {
              const { path, component, authority, redirectPath, ...rest } = rc;
              return (
                <AuthorizedRoute
                  key={path}
                  path={path}
                  component={component}
                  authority={authority}
                  redirectPath={redirectPath}
                  {...rest}
                />
              );
            })}
          </Switch>
        </React.Suspense>
      </React.Fragment>
    )
  }
}
export default Router;
