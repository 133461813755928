import React,{Component} from 'react';
import {Avatar, Layout, Badge, Tooltip, Menu, ConfigProvider} from 'antd'
import './styles.less'
import Router from "../components/routers";
import {
  DashboardOutlined,
  ProjectOutlined,
  SettingOutlined,
  CalendarOutlined,
  SearchOutlined,
  ForkOutlined,
} from '@ant-design/icons'
import HelperCenter from "./HelperCenter";
import Notices from "./Notices";
import ChangeCompany from "./ChangeCompany";
import zhCN from 'antd/lib/locale/zh_CN';
import 'moment/locale/zh-cn'
import {Link} from "react-router-dom";
import {BrowserRouter} from 'react-router-dom';

const {Sider, Content, Footer} = Layout

class Layouts extends Component{
  render() {
    return (
      <ConfigProvider locale={zhCN}>
        <BrowserRouter>
          <Layout>
            <Sider className={'lay-Sider'}>
              <Link to={'/'}>
                <Badge count={9} offset={[-8, 2]} className={'lay-Sider-logo'}>
                  <Avatar style={{ color: '#fff', backgroundColor: '#1890ff' }} size={50} src={"/"}>晓宇</Avatar>
                </Badge>
              </Link>

              <Menu theme="dark" mode="inline">
                <Menu.Item key={'10'} icon={<DashboardOutlined/>}>
                  <Tooltip title="Dashbord" placement="right" color='#2db7f5'>
                    <Link to={'/dashbord'}>1</Link>
                  </Tooltip>
                </Menu.Item>
                <Menu.Item key={'19'} icon={<ForkOutlined/>}>
                  <Tooltip title="线索" placement="right" color='#2db7f5'>
                    <Link to={'/clue'}>1</Link>
                  </Tooltip>
                </Menu.Item>
                <Menu.Item key={'20'} icon={<CalendarOutlined/>}>
                  <Tooltip title="我的日程" placement="right" color='#2db7f5'>
                    <Link to={'/calendar'}>1</Link>
                  </Tooltip>
                </Menu.Item>


                <Menu.Item key={'30'} icon={<ProjectOutlined/>}>
                  <Tooltip title="项目" placement="right" color='#2db7f5'>
                    <Link to={'/project'}>1</Link>
                  </Tooltip>
                </Menu.Item>
                <Menu.Item key={'45'} icon={<SearchOutlined />}>
                  <Tooltip title="内搜" placement="right" color='#2db7f5'>
                    <Link to={'/search'}>3</Link>
                  </Tooltip>
                </Menu.Item>
                <Menu.Item key={'40'} icon={<SettingOutlined />}>
                  <Tooltip title="Settings" placement="right" color='#2db7f5'>
                    <Link to={'/settings'}>3</Link>
                  </Tooltip>
                </Menu.Item>
                <Menu.Item key={'50'}>
                  <Notices />
                </Menu.Item>
                <Menu.Item key={'60'}>
                  <HelperCenter {...this.props} />
                </Menu.Item>
                <Menu.Item key={'70'}>
                  <ChangeCompany />
                </Menu.Item>
              </Menu>
            </Sider>
            <Layout className="site-layout" style={{marginLeft:' 60px', minHeight: '100vh'}}>
              <Content style={{ margin: '12px 12px 0', overflow: 'initial'}}>
                <div className="site-layout-background">
                  <Router />
                </div>
              </Content>
              <Footer style={{textAlign: 'center', color: 'rgba(0,0,0,.45)'}}>Ant Design ©2018 Created by Ant UED</Footer>
            </Layout>
          </Layout>
        </BrowserRouter>
      </ConfigProvider>
    );
  }
}
export default Layouts;
