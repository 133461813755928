import React, {Component} from "react";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {Drawer, Tooltip} from "antd";

class HelperCenter extends Component{
  state = {
    show: false
  }
  render() {
    const {show} = this.state
    return (
      <>
        <Tooltip title="帮助中心" placement="right" color='#2db7f5'>
          <div
            style={{width:"32px", height:"40px"}}
            onClick={()=>this.setState({show:true})}
          >
            <QuestionCircleOutlined/>
          </div>
        </Tooltip>
        <Drawer
          visible={show}
          onClose={()=>this.setState({show:false})}
          width={"700"}
        >
          这是 {window.location.hash.replace('#', '')} 页面的帮助文档。功能正在开发中。。。
        </Drawer>
      </>

    );
  }
}

export default HelperCenter
