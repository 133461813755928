import {Component} from "react";

class PageLoading extends Component{
  render() {
    return(
      <>
        <h2 style={{textAlign: 'center'}}>Project Manage</h2>
        <p style={{textAlign: 'center'}}>
          <img src="/logo192.png" alt={''} />
        </p>
        <p style={{textAlign: 'center'}}>loading....</p>
      </>
    )
  }
}
export default PageLoading
