import React, {Component} from "react";
import {BellOutlined} from "@ant-design/icons";
import {Drawer, Tooltip} from "antd";

class HelperCenter extends Component{
  state = {
    show: false
  }
  render() {
    const {show} = this.state
    console.log(window.location.hash)
    return (
      <>
        <Tooltip title="消息通知" placement="right" color='#2db7f5'>
          <div
            style={{width:"32px", height:"40px"}}
            onClick={()=>this.setState({show:true})}
          >
            <BellOutlined/>
          </div>
        </Tooltip>
        <Drawer
          visible={show}
          onClose={()=>this.setState({show:false})}
        >
          notice...
        </Drawer>
      </>

    );
  }
}

export default HelperCenter
