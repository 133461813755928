import {Component} from "react";
import styles from './styles.module.less'
import {Col, Row} from "antd";

class Dashbord extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {...props};
  // }
  dragStart(e) {
    this.currentTarget = e.currentTarget;
  }
  dragEnd(e) {
    this.currentTarget.style.display = 'block';
    e.target.classList.remove("drag-up");
    this.over.classList.remove("drag-up");

    e.target.classList.remove("drag-down");
    this.over.classList.remove("drag-down");


    var data = this.props.data;
    var from = this.currentTarget.dataset.key;
    var to = this.over.dataset.key;

    var selected = this.over.dataset?.selected === 'true' ? true : false;
    if(typeof to === 'undefined'){
      return;
    }
    data.splice(to, 0, data.splice(from, 1)[0]);
    data[to].selected = selected;

    //set sort to judge direction of drag and drop
    let noSelect = 0;
    let Select = 0;
    data = data.map((doc, index)=> {
      doc.selected ? Select++ : noSelect++
      doc.sort = index + 1;
      return doc;
    })
    // 两边必须有一个
    if(Select===data.length || noSelect===data.length){
      return;
    }

    this.props?.onChange(data)
  }
  dragOver(flag, e) {
    e.preventDefault();
    this.currentTarget.style.display = "none";
    if (e.target.tagName !== "DIV") {
      return;
    }

    //判断当前拖拽target 和 经过的target 的 sort
    const dgIndex = this.currentTarget.dataset.sort;
    const taIndex = e.target.dataset.sort;
    const animateName = dgIndex > taIndex ? "drag-up" : "drag-down";

    if (this.over && e.target.dataset.id !== this.over.dataset.id) {
      this.over.classList.remove("drag-up", "drag-down");
    }

    if(!e.target.classList.contains(animateName)) {
      e.target.classList.add(animateName);
      e.target.dataset.selected = flag;
      this.over = e.target;
      // this.over.dataset = {...e.target.dataset, selected: flag}
    }
  }
  render() {
    const {data} = this.props
    return (
      <Row gutter={12}>
        <Col span={8} onDragOver={this.dragOver.bind(this, false)} className ="contain" style={{borderRight: '1px solid rgba(0, 0, 0, 0.1)'}}>
          <Row >
            {data.map(((item, key)=>{
              if(item.selected){
                return null
              }
              return <Col
                className={styles.myCard}
                span={24}
                key={item.id}
                draggable='true'
                onDragEnd={this.dragEnd.bind(this)}
                onDragStart={this.dragStart.bind(this)}
                data-id={item.id}
                data-key={key}
                data-sort={item.sort}
                data-selected={item.selected}
              >
                <img className={styles.icon} src={''} alt={''}></img>
                <div className={styles.contents}>
                  <h4>{item.title}</h4>
                  <p>{item.content}</p>
                </div>
              </Col>
            }))}
          </Row>
        </Col>
        <Col span={16} onDragOver={this.dragOver.bind(this, true)} className ="contain">
          <Row>
            {data.map(((item, key)=>{
              if(!item.selected){
                return null
              }
              return <Col
                className={styles.myCard}
                span={12}
                key={item.id}
                draggable='true'
                onDragEnd={this.dragEnd.bind(this)}
                onDragStart={this.dragStart.bind(this)}
                data-id={item.id}
                data-key={key}
                data-sort={item.sort}
                data-selected={item.selected}
              >
                <img className={styles.icon} src={''} alt={''}></img>
                <div className={styles.contents}>
                  <h4>{item.title}</h4>
                  <p>{item.content}</p>
                </div>
              </Col>
            }))}
          </Row>
        </Col>
      </Row>
    )
  }
}

export default Dashbord
