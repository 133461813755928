import {Avatar, Skeleton, Row, Col, Statistic, Card, List, Modal} from "antd";
import {Component} from "react";
import moment from 'moment'
import {SettingOutlined} from "@ant-design/icons";
import TaskCard from './components/TaskCard'
import styles from './styles.module.less'

const ExtraContent = () => (
  <>
    <div className={styles.extraContent}>
      <div className={styles.statItem}>
        <Statistic title="项目数" value={56} />
      </div>
      <div className={styles.statItem}>
        <Statistic title="团队内排名" value={8} suffix="/ 24" />
      </div>
      <div className={styles.statItem}>
        <Statistic title="项目访问" value={2223} />
      </div>
    </div>
  </>
);

class Index extends Component{
  state={
    showSetting: false,
    data: [
      {id: 1, title:'title1', content: 'content1', sort: 1, selected: false},
      {id: 2, title:'title2', content: 'content2', sort: 2, selected: false},
      {id: 3, title:'title3', content: 'content3', sort: 3, selected: false},
      {id: 4, title:'title4', content: 'content4', sort: 4, selected: false},
      {id: 5, title:'title5', content: 'content5', sort: 5, selected: true},
      {id: 6, title:'title6', content: 'content6', sort: 6, selected: true},
      {id: 7, title:'title7', content: 'content7', sort: 7, selected: true},
    ]
  }
  PageHeaderContent = ({ currentUser }) => {
    const loading = currentUser && Object.keys(currentUser).length;

    if (!loading) {
      return (
        <Skeleton
          avatar
          paragraph={{
            rows: 1,
          }}
          active
        />
      );
    }

    return (
      <div className={styles.pageHeaderContent}>
        <div className={styles.avatar}>
          <Avatar size="large" src={currentUser.avatar} />
        </div>
        <div className={styles.content}>
          <div className={styles.contentTitle}>
            早安，
            {currentUser.name}
            ，祝你开心每一天！
          </div>
          <div>
            {currentUser.group} | 工作台 | <SettingOutlined onClick={()=> {this.setState({showSetting: true})}}/>
          </div>
        </div>
      </div>
    );
  };
  renderActivities = (item) => {
    const events = item.template.split(/@\{([^{}]*)\}/gi).map((key) => {
      if (item[key]) {
        return (
          <a href={item[key].link} key={item[key].name}>
            {item[key].name}
          </a>
        );
      }

      return key;
    });
    return (
      <List.Item key={item.id}>
        <List.Item.Meta
          avatar={<Avatar src={item.user.avatar} />}
          title={
            <span>
              <a href={'/'} className={styles.username}>{item.user.name}</a>
              &nbsp;
              <span className={styles.event}>{events}</span>
            </span>
          }
          description={
            <span className={styles.datetime} title={item.updatedAt}>
              {moment(item.updatedAt).fromNow()}
            </span>
          }
        />
      </List.Item>
    );
  };

  render() {
    const current = {name:'hhha', avatar: 'http://www.baidu.com/', title: "哈哈哈哈哈哈哈哈哈哈", group: "技术部"}
    const projectNotice = [
      {id:1, description: 'hhha', href: '/abc', title: 'titles', logo: "123", member: '搬家'},
      {id:2, description: 'hhha', href: '/abc', title: 'titles', logo: "123", member: '搬家'},
    ]
    const activities = [{"id":"trend-1","updatedAt":"2021-08-05T03:24:01.024Z","user":{"name":"曲丽丽","avatar":"https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png"},"group":{"name":"高逼格设计天团","link":"http://github.com/"},"project":{"name":"六月迭代","link":"http://github.com/"},"template":"在 @{group} 新建项目 @{project}"},{"id":"trend-2","updatedAt":"2021-08-05T03:24:01.024Z","user":{"name":"付小小","avatar":"https://gw.alipayobjects.com/zos/rmsportal/cnrhVkzwxjPwAaCfPbdc.png"},"group":{"name":"高逼格设计天团","link":"http://github.com/"},"project":{"name":"六月迭代","link":"http://github.com/"},"template":"在 @{group} 新建项目 @{project}"},{"id":"trend-3","updatedAt":"2021-08-05T03:24:01.024Z","user":{"name":"林东东","avatar":"https://gw.alipayobjects.com/zos/rmsportal/gaOngJwsRYRaVAuXXcmB.png"},"group":{"name":"中二少女团","link":"http://github.com/"},"project":{"name":"六月迭代","link":"http://github.com/"},"template":"在 @{group} 新建项目 @{project}"},{"id":"trend-4","updatedAt":"2021-08-05T03:24:01.024Z","user":{"name":"周星星","avatar":"https://gw.alipayobjects.com/zos/rmsportal/WhxKECPNujWoWEFNdnJE.png"},"project":{"name":"5 月日常迭代","link":"http://github.com/"},"template":"将 @{project} 更新至已发布状态"},{"id":"trend-5","updatedAt":"2021-08-05T03:24:01.024Z","user":{"name":"朱偏右","avatar":"https://gw.alipayobjects.com/zos/rmsportal/ubnKSIfAJTxIgXOKlciN.png"},"project":{"name":"工程效能","link":"http://github.com/"},"comment":{"name":"留言","link":"http://github.com/"},"template":"在 @{project} 发布了 @{comment}"},{"id":"trend-6","updatedAt":"2021-08-05T03:24:01.024Z","user":{"name":"乐哥","avatar":"https://gw.alipayobjects.com/zos/rmsportal/jZUIxmJycoymBprLOUbT.png"},"group":{"name":"程序员日常","link":"http://github.com/"},"project":{"name":"品牌迭代","link":"http://github.com/"},"template":"在 @{group} 新建项目 @{project}"}]
    const {showSetting, data} = this.state

    return(
      <>
        <div className={styles.block}>
          <Row gutter={[12, 12]}>
            <Col xs={{ span: 24 }} lg={{ span: 16,}}>
              <this.PageHeaderContent currentUser={current} />
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8,}}>
              <ExtraContent />
            </Col>
          </Row>
        </div>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16}>
            <Card
              className={styles.projectList}
              style={{
                marginBottom: 12,
              }}
              title="我的代办"
              bordered={false}
              extra={<a href="/">分类筛选 </a>}
              // loading={projectLoading}
              bodyStyle={{
                padding: 0,
              }}
            >
              {projectNotice.map((item) => (
                <Card.Grid className={styles.projectGrid} key={item.id}>
                  <Card
                    bodyStyle={{
                      padding: 0,
                    }}
                    bordered={false}
                  >
                    <Card.Meta
                      title={
                        <div className={styles.cardTitle}>
                          <Avatar size="small" src={item.logo} />
                          <a href={item.href}>{item.title}</a>
                        </div>
                      }
                      description={item.description}
                    />
                    <div className={styles.projectItemContent}>
                      <a href={item.memberLink}>{item.member || ''}</a>
                      {item.updatedAt && (
                        <span className={styles.datetime} title={item.updatedAt}>
                          {moment(item.updatedAt).fromNow()}
                        </span>
                      )}
                    </div>
                  </Card>
                </Card.Grid>
              ))}
            </Card>
            <Card
              bodyStyle={{
                padding: 0,
                marginBottom: 12,
              }}
              bordered={false}
              className={styles.activeCard}
              title="动态"
              extra={<p>查看全部</p>}
              // loading={activitiesLoading}
            >
              <List
                // loading={activitiesLoading}
                renderItem={(item) => this.renderActivities(item)}
                dataSource={activities}
                className={styles.activitiesList}
                size="large"
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Card
              style={{
                marginBottom: 12,
              }}
              title="设置卡片布局"
              bordered={false}
              bodyStyle={{
                padding: 0,
              }}
            >
              asdfsaf
              {/*<EditableLinkGroup onAdd={() => {}} links={links} linkElement={Link} />*/}
            </Card>
            <Card
              bodyStyle={{
                padding: 0,
                marginBottom: 12,
              }}
              bordered={false}
              className={styles.activeCard}
              title="动态"
              extra={<p>查看全部</p>}
              // loading={activitiesLoading}
            >
              <List
                // loading={activitiesLoading}
                renderItem={(item) => this.renderActivities(item)}
                dataSource={activities}
                className={styles.activitiesList}
                size="large"
              />
            </Card>

            <Card
              style={{
                marginBottom: 12,
              }}
              bordered={false}
              title="XX 指数"
              // loading={radarData.length === 0}
            >
              <div className={styles.chart}>
                charts
                {/*<Radar hasLegend height={343} data={radarData} />*/}
              </div>
            </Card>
            <Card
              bodyStyle={{
                paddingTop: 12,
                paddingBottom: 12,
              }}
              bordered={false}
              title="团队"
              // loading={projectLoading}
            >
              <div className={styles.members}>
                <Row gutter={48}>
                  {projectNotice.map((item) => (
                    <Col span={12} key={`members-item-${item.id}`}>
                      <a href={item.href}>
                        <Avatar src={item.logo} size="small" />
                        <span className={styles.member}>{item.member}</span>
                      </a>
                    </Col>
                  ))}
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
        <Modal
          visible={showSetting}
          width={800}
          closable={false}
          onCancel={()=>{this.setState({showSetting: false})}}
        >
          <TaskCard data={data} onChange={(d)=>{this.setState({data: d})}} />
        </Modal>
      </>
    )
  }
}

export default Index
